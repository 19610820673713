<template>
  <section v-if="itineraryDays">
    <ItineraryInfoDay
      v-for="(data, index) in itineraryDays"
      :key="index"
      :index="index"
      :data="data"
      :services_itinerary="services_itinerary"
      :setLoading="setLoading"
      :loadServicesItinerary="loadServicesItinerary"
      :loadItineraryDays="loadItineraryDays"
      :validateFunctionExecution="validateFunctionExecution"
    />
  </section>
</template>

<script>
import cloneDeep from "lodash.clonedeep";
import { mapGetters } from "vuex";
import ItineraryInfoDay from "./ItineraryInfoDay.vue";

export default {
  name: "ItineraryDay",
  components: {
    ItineraryInfoDay,
  },
  props: {
    itineraryDays: {
      type: Array,
      require: true,
    },
    services_itinerary: {
      type: [Array, Object],
      default: () => [],
    },
    setLoading: {
      type: Function,
      default: () => {},
    },
    loadServicesItinerary: {
      type: Function,
      default: () => {},
    },
    loadItineraryDays: {
      type: Function,
      default: () => {},
    },
    validateFunctionExecution: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    copyImgFullToImg() {
      const newItineraryDays = cloneDeep(this.itineraryDays);
      newItineraryDays.forEach((day) => {
        if (!day.images_full) return;
        day.images = Array.isArray(this.itineraryDays.images) ? day.images : [];
        day.images_full.forEach((imgFull) => {
          const imgFound = day.images.find((img) => img.id === imgFull.id);
          if (!imgFound) day.images.push(imgFull);
        });
      });
      this.$store.dispatch("itineraryDays/setItineraryDays", newItineraryDays);
    },
  },
  mounted() {
    this.copyImgFullToImg();
  },
  computed: {
    ...mapGetters({
      countries: "client/getCountriesFromContract",
    }),
  },
};
</script>
